"use strict"

/**
 * @typedef {GET.Services.reseller.id.customisations.output} brandIn
 */

class Brand {
    /**
     *
     * @param {?brandIn} brand_in
     * @param {?SimpleService} simple_service
     */
    constructor(brand_in, simple_service = null) {
        if(brand_in) {
            this.brandDomain =
                brand_in.brandDomain
            this.businessName =
                brand_in.businessName
            this.databaseServiceSubdomain =
                brand_in.databaseServiceSubdomain
            this.filemanagerServiceSubdomain =
                brand_in.filemanagerServiceSubdomain
            this.webbuilderServiceSubdomain =
                brand_in.webbuilderServiceSubdomain
            this.homeUrl =
                brand_in.homeUrl
            this.primaryStackcpDomain = brand_in.primaryStackcpDomain
            this.salesEmailAddress =
                brand_in.salesEmailAddress
            this.sshServiceSubdomain =
                brand_in.sshServiceSubdomain
            this.sslBrandedWebmailDomain = brand_in.sslBrandedWebmailDomain
            this.supportEmailAddress =
                brand_in.supportEmailAddress
            this.supportUrl =
                brand_in.supportUrl
            this._webmailDomain =
                brand_in.webmailDomain
            this.webmailSubdomain =
                brand_in.webmailSubdomain
            this.sslBrandedDomain = brand_in.sslBrandedDomain
            this.owningUser = brand_in.owningUser

            /** @type {{[x: string]: boolean}} */
            if( brand_in.togglePrefs && typeof brand_in.togglePrefs == 'string' ){
                this.togglePrefs = JSON.parse(brand_in.togglePrefs);
            } else if ( typeof brand_in.togglePrefs == 'object' ){
                this.togglePrefs = brand_in.togglePrefs;
            } else {
                this.togglePrefs = {};
            }
        }
        this.service = simple_service
    }
    get domain() {
        if(this.brandDomain) {
            if(this.brandDomain == "*") {
                return this.service ? this.service.headerName : null
            } else {
                return this.brandDomain
            }
        } else {
            return null
        }
    }
    get databaseAdminUrlPrefix() {
        if(
            this.databaseServiceSubdomain &&
            this.domain
        ) {
            let prefix = this.databaseServiceSubdomain
            return `http://${prefix}.${this.domain}/`
        } else {
            return null
        }
    }
    get fileManagerUrlPrefix() {
        if(
            this.filemanagerServiceSubdomain &&
            this.domain
        ) {
            let prefix = this.filemanagerServiceSubdomain
            return `http://${prefix}.${this.domain}/`
        } else {
            return "https://filemanager.stackcp.com/"
        }
    }
    get sshHostname() {
        if(
            this.domain &&
            this.sshServiceSubdomain
        ) {
            let prefix = this.sshServiceSubdomain
            return `${prefix}.${this.domain}`
        } else {
            return "ssh.stackcp.com"
        }
    }
    get ftpHostname() {
        if(
            this.domain &&
            this.ftpServiceSubdomain
        ) {
            let prefix = this.ftpServiceSubdomain
            return `${prefix}.${this.domain}`
        } else {
            return "ftp.stackcp.com"
        }
    }
    get webmailDomain() {
        if(this._webmailDomain) {
            if(this._webmailDomain == "*") {
                return this.service ? this.service.headerName : null
            } else {
                return this._webmailDomain
            }
        } else {
            return null
        }
    }
    get webmailUrl() {
        if(!this.webmailDomain) return null
        const scheme = this.sslBrandedWebmailDomain ?
            "https" :
            "http"
        const prefix = this.webmailSubdomain ?
            `${this.webmailSubdomain}.` :
            ""
        const domain = this.webmailDomain == "*" ?
            this.primaryStackcpDomain :
            this.webmailDomain
        return `${scheme}://${prefix}${domain}`
    }
    get webmailUrlPrefix() {
        if(
            this.webmailSubdomain &&
            this.webmailDomain
        ) {
            let prefix = this.webmailSubdomain
            return `http://${prefix}.${this.webmailDomain}/`
        } else {
            return null
        }
    }
    get webBuilderUrlPrefix() {
        if(
            this.webbuilderServiceSubdomain &&
            this.domain
        ) {
            let prefix = this.webbuilderServiceSubdomain
            return (this.sslBrandedDomain ? 'https' : 'http')+`://${prefix}.${this.domain}/`
        } else {
            return "https://webbuilder.stackcp.com/"
        }
    }
}
